//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { removeWatermark, setWaterMark } from '@/utils/watermark.js'

import {
  _getapplyconfirm,
  _getspconfirm,
  _getassign,
  _getcomplete,
  _getcheck,
  _gettakeorder,
  _getorderinfo,
} from '@/api/disabilities.js'

import {
  _getMine
} from '@/api/user.js'
import ConfirmDia from './confirmDia.vue'
import Orders from './orders.vue'
import Redeploy from './redeploy.vue'
import Acceptance from './acceptance.vue'
import Approvalbtn from './approval.vue'
import ChangeApplication from '../Appapply/ChangeApplication.vue'
import Asdbode from './asdbode.vue'

export default {
  components: {
    ConfirmDia,
    Orders,
    Redeploy,
    Acceptance,
    Approvalbtn,
    ChangeApplication,
    Asdbode,
  },
  data() {
    return {
      dataArr: {
        equipments: [],
        histroy: [],
        info: {
          reportInfo: '',
        },
      },
      aaa: [],
      bbb: [],
      checkersName: '',
      examinName: '',
      equipments: [],
      imgsList: [], //照片回显数据
      fileVList: [], //音频会显数组
      dialogImageUrl: '', //查看大图
      dialogVisible: false,
      videoForm: [],
      AudioForm: [],
      registerType: '',
      roleType: '',
    }
  },
  created() {
    let from = {
      type: this.$route.query.type,
      id: this.$route.query.id,
      orderId: '',
      reportLevel: '',
    }
    this.getorderinfo(from)
    this.registerType = JSON.parse(localStorage.getItem('registerType'))
    this.roleType = JSON.parse(localStorage.getItem('roleType')) //获取用户角色类型
  },
  mounted() {
    _getMine({}).then(res => {
      console.log(res.data);
      let data = res.data;
      let name = data.name;
      let phone = data.phone.substr(7)
      setWaterMark(`${name}(${phone})`,"")
    })
  },
  destroyed() {
    removeWatermark()
  },
  methods: {
    // 回调事件
    getinfo() {
      let from = {
        type: this.$route.query.type,
        id: this.$route.query.id,
        orderId: '',
        reportLevel: '',
      }
      this.getorderinfo(from)
    },
    // 获取详细数据
    getorderinfo(from) {
      _getorderinfo(from).then((res) => {
        this.dataArr = res.data
        this.aaa = []
        this.bbb = []
        this.imgsList = []
        this.AudioForm = []
        this.videoForm = []
        this.dataArr.info.checkers.forEach((item, index) => {
          this.aaa.push(item.name)
        })
        this.checkersName = this.aaa.join()
        this.dataArr.info.examineAndApproveUser.forEach((item, index) => {
          this.bbb.push(item.name)
        })
        this.examinName = this.bbb.join()
        // attType 1视频 2音频 3图片
        this.dataArr.info.ossIds.forEach((item) => {
          if (item.attType == 3) {
            this.imgsList.push({
              url:
                '/ananops/api/v1/pub/file/download?ossId=' +
                `${item.ossId}` +
                '&type=pc',
            })
          } else if (item.attType == 2) {
            this.AudioForm.push({
              url:
                '/ananops/api/v1/pub/file/download?ossId=' +
                `${item.ossId}` +
                '&type=pc',
            })
          } else if (item.attType == 1) {
            this.videoForm.push({
              url:
                '/ananops/api/v1/pub/file/download?ossId=' +
                `${item.ossId}` +
                '&type=pc',
            })
          }
        })
      })
    },
    // 获取总时长
    async getDuration(e) {
      const firsthandAudio = e.target
      while (firsthandAudio.duration === Infinity) {
        await new Promise((r) => setTimeout(r, 200))
        firsthandAudio.currentTime = 10000000 * Math.random()
      }
      this.duration = firsthandAudio.duration
    },
    // 点击大图显示
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 回退
    goBack() {
      this.$router.back()
    },
    // 审批
    approvalbtn() {
      this.$refs.approvalbtn.init(this.dataArr.info.id)
    },
    // 确认
    confirmbtn() {
      this.$refs.confirmDia.init(this.dataArr.info.id, this.dataArr.info.price)
    },
    // 指派
    assignedbtn() {
      this.$refs.redeploy.init(this.dataArr.info.id)
    },
    // 转派
    turnsendbtn(type) {
      this.$refs.orders.init(this.dataArr.info.id, type)
    },
    // 拒绝
    refusedbtn(type) {
      this.$refs.orders.init(this.dataArr.info.id, type)
    },
    // 接单
    orderbtn(type) {
      let from = {
        takeStatus: type,
        orderId: this.dataArr.info.id,
      }
      this.$confirm('是否确认接单', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        _gettakeorder(from).then((res) => {
          if (res.code == '1') {
            this.$router.push({
              path: '/main/report/order',
              query: { selected: '50' },
            })
            this.$message(res.msg)
          }
        })
      })
    },
    // 下载的时候
    download(file) {
      this.attachmentDownload(file.url)
    },
    attachmentDownload(url) {
      var a = document.createElement('a')
      // 用户与指针设备( 如鼠标 )交互时发生的事件
      var event = new MouseEvent('click')
      // 跳转的路劲
      a.href = url
      // 向a派发鼠标事件
      a.dispatchEvent(event)
    },
    // 设备申请
    quipmentbtn() {
      this.$refs.changeApplication.open(
        '设备申请',
        this.dataArr.info.orderId,
        this.dataArr.info.id,
      )
    },
    // 完成
    completebtn() {
      console.log(this.dataArr)
      let { equipments, info } = this.dataArr
      let iseq = false
      if (info.applyType == 0) {
        iseq = equipments.every((item) => {
          return item.eaStatus == 2 || item.eaStatus == 4
        })
      } else {
        iseq = equipments.every((item) => {
          return item.eaStatus == 1 || item.eaStatus == 4
        })
      }
      if (iseq) {
        this.$refs.asdbode.init(this.dataArr.info.id)
      } else {
        this.$message('设备申请未完成')
      }

      // let from = {
      //   id: this.dataArr.info.id,
      //   repareAdvice: "string",
      //   repareResults: "string",
      //   reportReason: "string",
      // };
      // this.$confirm("是否确定完成", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   _getcomplete(from).then((res) => {
      //     if (res.code == "1") {
      //       this.$router.push({
      //         path: "/main/report/order",
      //         query: { selected: "70" },
      //       });
      //       this.$message(res.msg);
      //     }
      //   });
      // });
    },
    // 验收
    acceptancebtn() {
      this.$refs.acceptance.init(this.dataArr.info.id)
    },
  },
}
