//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getapplyconfirm, _getSupConfim } from '@/api/disabilities.js'
export default {
  watch: {
    'form.spConfirmStatus'(val) {
      this.swith = val == 0 ? true : false
    },
  },
  data() {
    return {
      swith: false,
      btnDisab: false,
      dialogVisible: false,
      options: [
        { name: '是', id: '1' },
        { name: '否', id: '0' },
      ],
      dataOptions: [],
      form: {
        orderId: '',
        spConfirmStatus: '1', //是否通过
        superTargetId: '', //审批人的id
        spConfirmReason: '', //拒绝原因
      },
      rules: {
        spConfirmStatus: [
          { required: true, message: '请选择是否通过', trigger: 'change' },
        ],
      },
    }
  },
  methods: {
    init(id) {
      this.dialogVisible = true
      this.form.orderId = id
      this.getSupConfim()
    },
    getSupConfim() {
      _getSupConfim({}).then(res => {
        this.dataOptions = res.data
        this.dataOptions.push({ name: '无', id: '' })
      })
    },
    // 确认
    adduser() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.btnDisab = true
          _getapplyconfirm(this.form)
            .then(res => {
              if (res.code == '1') {
                this.btnDisab = false
                if (this.form.superTargetId) {
                  this.$router.push({
                    path: '/main/report/order',
                    query: { selected: '10' },
                  })
                } else {
                  this.$router.push({
                    path: '/main/report/order',
                    query: { selected: '30' },
                  })
                }

                this.$message(res.msg)
              } else {
                this.btnDisab = false
              }
            })
            .catch(() => {
              this.btnDisab = false
            })
        }
      })
    },
    close() {
      this.form = {}
      this.dataOptions = []
      this.$refs['form'].resetFields()
    },
  },
}
