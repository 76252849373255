//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getcomplete } from "@/api/disabilities.js";
export default {
  data() {
    return {
      dialogVisible: false,
      btnDisab:false,
      form: {
        id: "",
        repareAdvice: "",
        repareResults: "",
        reportReason: "",
      },
    };
  },
  methods: {
    init(id) {
      this.dialogVisible = true;
      this.form.id = id;
    },
    // 确认
    adduser() {
      this.btnDisab = true
      _getcomplete(this.form).then((res) => {
        if (res.code == "1") {
           this.btnDisab = false
          this.$router.push({
            path: "/main/report/order",
              query: { selected: "70" },
          });
          this.$message(res.msg);
        }else{
          this.btnDisab = false
        }
      }).catch(()=>{
        this.btnDisab = false
      });
    },
    close() {
      this.form = {
        id: "",
        checkScore: 5,
        checkEvaluate: "",
        checkQScore: 5,
        checkAScore: 5,
      }
    },
  },
};
