//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getspconfirm , _getspconfirmAndAssign,_getworkerInfo} from "@/api/disabilities.js";
import { _getcode } from "@/api/organizational";

export default {
  data() {
    return {
      dialogVisible: false,
      btnDisab:false,
      form: {
        orderId: '',
        spReportInfo: '', //补充故障说明
        spConfirmStatus: '1', //服务商确认状态
        refReason: '', //原因
        price: '', //故障金额

        assignType: '', //派单类型（不为空）1为指向对应工程师，2为工单市场
        engineerId: '', //服务工程师ID
        // orderId: '',
      },
      swith: true,
      list: [],
      lists: [],
      engList: [],
      disabledt: false,
    };
  },
  watch: {
    "form.spConfirmStatus"(val) {
      this.swith = val == 1 ? true : false;
    },
  },

  methods: {
    init(id,price) {
      this.dialogVisible = true;
      this.form.orderId = id;
      this.form.price = price;
      this.getcode();

      this.getworkerInfo()
    },
   getcode() {
      let from = {
        para: 'pdlx',
      }
      _getcode(from).then(res => {
        this.list = res.data
      })
      let froms = {
        para: 'gzlx',
      }
      _getcode(froms).then(res => {
        this.lists = res.data
      })
    },
      // 类型的切换事件
    assignbtn(type) {
      //如果是工单市场  指定工程师不出现
      if (type == '2') {
        this.disabledt = true
      } else {
        this.disabledt = false
      }
    },
    getworkerInfo() {
      _getworkerInfo({}).then(res => {
        this.engList = res.data
      })
    },
    // 确认
    adduser() {
      if(this.form.spConfirmStatus == '1'){
        this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnDisab = true
        this.form.price = !this.form.price?0:parseFloat(this.form.price);
        //  console.log(this.form)
         _getspconfirmAndAssign(this.form).then(res => {
           console.log(res)
             if (res.code == "1") {
              this.btnDisab = false
              this.$router.push({
                path: "/main/report/order",
                query: { selected: res.data.code },
              });
              this.$message(res.msg);
            }else{
              this.btnDisab = false
            }
         }).catch(()=>{
            this.btnDisab = false
          });
          
          // _getspconfirm(this.form).then((res) => {
          //   if (res.code == "1") {
          //     this.btnDisab = false
          //     this.$router.push({
          //       path: "/main/report/order",
          //       query: { selected: "30" },
          //     });
          //     this.$message(res.msg);
          //   }else{
          //     this.btnDisab = false
          //   }
          // }).catch(()=>{
          //   this.btnDisab = false
          // });
        }
      });
      }else{
         _getspconfirm(this.form).then((res) => {
            if (res.code == "1") {
              this.$router.push({
                path: "/main/report/order",
                query: { selected: "30" },
              });
              this.$message(res.msg);
            }
          });
      }

    },
    close() {
      console.log(this.form)
      // this.form = { orderId: "",
      //   spReportInfo: "", //补充故障说明
      //   spConfirmStatus: "1", //服务商确认状态
      //   refReason: "", //原因
      //   price: "", //故障金额
      //   };
      //  this.$refs["form"].resetFields();
    },
  },
};
