//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getassign, _getworkerInfo } from '@/api/disabilities.js'
import { _getcode } from '@/api/organizational'

export default {
  data() {
    return {
      dialogVisible: false,
      btnDisab: false,
      form: {
        assignType: '', //派单类型（不为空）1为指向对应工程师，2为工单市场
        engineerId: '', //服务工程师ID
        orderId: '',
      },
      list: [],
      engList: [],
      disabledt: false,
    }
  },
  methods: {
    init(id) {
      this.dialogVisible = true
      this.form.orderId = id
      this.getcode()
      this.getworkerInfo()
    },
    getcode() {
      let from = {
        para: 'pdlx',
      }
      _getcode(from).then(res => {
        this.list = res.data
      })
    },
    // 类型的切换事件
    assignbtn(type) {
      //如果是工单市场  指定工程师不出现
      if (type == '2') {
        this.disabledt = true
      } else {
        this.disabledt = false
      }
    },
    getworkerInfo() {
      _getworkerInfo({}).then(res => {
        this.engList = res.data
      })
    },
    // 确认
    adduser() {
      this.btnDisab = true
      _getassign(this.form)
        .then(res => {
          if (res.code == '1') {
            this.btnDisab = false
            if (this.form.assignType == 1) {
              this.$router.push({
                path: '/main/report/order',
                query: { selected: '40' },
              })
            } else {
              this.$router.push({
                path: '/main/report/order',
                query: { selected: '35' },
              })
            }

            this.$$message(res.msg)
          } else {
            this.btnDisab = false
          }
        })
        .catch(() => {
          this.btnDisab = false
        })
    },
    close() {
      this.form = {}
    },
  },
}
