//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _gettakeorder, _getworkerInfoChange } from "@/api/disabilities.js";
import { _getcode } from "@/api/organizational";

export default {
  data() {
    return {
      dialogVisible: false,
      btnDisab:false,
      list: [],
      form: {
        engineerId: "", //转派服务工程师ID
        orderId: "", //工单id（不为空）
        reason: "", //原因
        takeStatus: "", //接单状态(必填) 1同意 2 拒绝 3 转派
      },
    };
  },

  methods: {
    init(id, type) {
      this.dialogVisible = true;
      this.form.orderId = id;
      this.form.takeStatus = type;//传过来的拒绝还是同意
      this.getworkerInfo();
    },
    getworkerInfo() {
      console.log(22222222)
      _getworkerInfoChange({}).then((res) => {
        this.list = res.data;
      });
    },
    // 确认
    adduser() {
      this.btnDisab = true
      _gettakeorder(this.form).then((res) => {
        if (res.code == "1") {
          this.btnDisab = false
          if (this.form.takeStatus == "2") {//拒绝
            this.$router.push({
              path: "/main/report/order",
              query: { selected: "90" },
            });
            this.$message(res.msg);
          } else if (this.form.takeStatus == "3") {//转派
            this.$router.push({
              path: "/main/report/order",
              query: { selected: "40" },
            });
            this.$message(res.msg);
          }
        }else{
          this.btnDisab = false
        }
      }).catch(()=>{
        this.btnDisab = false
      });
    },
    close() {
      this.form = {};
    },
  },
};
