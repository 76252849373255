//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { removeWatermark, setWaterMark } from '@/utils/watermark.js'
import {
  _getMine
} from '@/api/user.js'

import {
  _getorderInfo,
  _getengineerDispatch,
  _getorderImplement,
  _getcheck,
  _getorderOver,
  _getorderImplements,
  _freeOrderImplement,
} from "@/api/maintenance";
import Assigned from "./assigned.vue";
import Asas from "./asas.vue";
import ChangeApplication from "../../Maintenance/Application/ChangeApplication.vue";
import Mapview from "./mapview.vue";
import Headers from "../../../../components/Headers.vue";
export default {
  components: {
    Assigned,
    Asas,
    ChangeApplication,
    Mapview,
    Headers,
  },
  data() {
    return {
      flag:false,
      type: "",
      roleType: "", //角色类型
      registerType:"",//用户类型
      btnDisab:false,
      tableData: {
        name: "",
        orderId: "",
        orderStatusName: "",
        createTime: "",
        count: "",
        ddname: "",
        uphone: "",
        respUname: "",
        startTime: "",
        engineers: "",
        respUname: "",
        inspectionTime: "",
      },
      dataArr: [], //提交的事件线数组
      newId: "", //往后端传的字段
      contentList: [
        //维保数据
        {
          ddname: "", //部门名称
          // deptId: "", //维保工单末班
          allTF: false,
          serviceList: [
            {
              datas: [
                {
                  id: "",
                  name: "", //服务名称
                  content: "", //服务内容
                  standards: "", // 服务标准
                  inspectResults: "0",
                  ps:"",//备注
                },
              ],
            },
          ],
          content: [
            {
              id: "",
              name: "", //名称
              firm: "", //厂商
              eprice: "",
              emodel: "",
              // templateId: "",
              enumber: "",
              inspectResults: "0",
              serviceStandarder: [
                {
                  contentName: "",
                  standard: "",
                },
              ],
            },
          ],
        },
      ],
      // 游离设备的数据
      freeEquipments: [
        {
          dDname: "", // 部门名称
          dDid: "", //部门id
          spDname: "", //服务商名称
          spDid: "", //服务商id
          equipments: [
            {
              name: "", //设备名称
              firm: "", //厂商
              eNumber: "", //数量
              ePrice: "", //单价
              eModel: "", //型号
              signAddres: "",
              signLat: "",
              signLng: "",
              templateId: "", //模板id
              templateArr: [], //设备模板的数据
              serviceStandarder: [
                {
                  contentName: "", //维保内容
                  standard: "", //维保完成标准
                },
              ],
            },
          ],
        },
      ],
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.roleType = JSON.parse(localStorage.getItem("roleType")); //获取用户角色类型
    this.registerType = JSON.parse(localStorage.getItem("registerType")); //获取用户角色类型
    this.getorderinfo({
      orderId: this.$route.query.orderId,
      orderStatus: this.$route.query.type,
      spDid:this.$route.query.spDid
    });
    console.log(this)
  },
  mounted() {
    _getMine({}).then(res => {
      console.log(res.data);
      let data = res.data;
      let name = data.name;
      let phone = data.phone.substr(7)
      setWaterMark(`${name}(${phone})`,"")
    })
  },
  destroyed() {
    removeWatermark()
  },
  methods: {
    //tab表头样式
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:30px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0";
      }
    },
    query() {
      //回调刷新页面
      this.getorderinfo({
        orderId: this.$route.query.orderId,
        orderStatus: this.$route.query.type,
        spDid:this.$route.query.spDid
      });
    },
    goBack() {
      this.$router.back();
    },
    // 选择地址
    mapBtn(index, indexs) {
      this.$refs.mapview.init(index, indexs);
    },
    // 全选和反选
    changeCheckbox(val, index, item) {
      this.$confirm("是否全选", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let from = {
            id: item.id,
            inspectResults: val ? "1" : "0",
          };
          _getorderImplements(from).then((res) => {
            if (res.code == "1") {
              // this.contentList.forEach((item) => {
              //   item.serviceList.forEach((iovs) => {
              //     iovs.datas.forEach((desp) => {
              //       desp.inspectResults = "1";
              //     });
              //   });
              // });
              // this.content.forEach((item) => {
              //   item.inspectResults = "1";
              // });
            }
          });
        })
        .catch(() => {
          this.contentList[index].allTF = !val;
        });
    },
    // 网点服务单选
    radioChange(val, index, indexs) {
      this.$confirm("是否修改工单完成状态", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          let from = {
            id: this.contentList[index].serviceList[0].datas[indexs].id,
            inspectResults:
              this.contentList[index].serviceList[0].datas[indexs]
                .inspectResults,
                ps:this.contentList[index].serviceList[0].datas[indexs].ps
          };
          _getorderImplement(from).then((res) => {
            if (res.code == "1") {
              this.$message(res.msg);
            }else{
               this.contentList[index].serviceList[0].datas[indexs].inspectResults =
            val == "1" ? "0" : "1";
            }
          });
        })
        .catch(() => {
          this.contentList[index].serviceList[0].datas[indexs].inspectResults =
            val == "1" ? "0" : "1";
        });
    },
    //网点设备单选
    radionNewChange(val, index, indexs) {
      this.$confirm("是否修改工单完成状态", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          let from = {
            id: this.contentList[index].content[indexs].id,
            inspectResults:
              this.contentList[index].content[indexs].inspectResults,
          };
          _getorderImplement(from).then((res) => {
            if (res.code == "1") {
              this.$message(res.msg);
            }
          });
        })
        .catch(() => {
          this.contentList[index].content[indexs].inspectResults =
            val == "1" ? "0" : "1";
        });
    },
    closeMoule(from) {
      this.freeEquipments[from.oneDp].equipments[from.twoPd].signAddres =
        from.address;
      this.freeEquipments[from.oneDp].equipments[from.twoPd].signLat = from.lat;
      this.freeEquipments[from.oneDp].equipments[from.twoPd].signLng = from.lng;
    },
    // 游离设备的提交按钮
    submit(index, indexs) {
      if (
        this.freeEquipments[index].equipments[indexs].signAddres != undefined
      ) {
        let from = {
          id: this.freeEquipments[index].equipments[indexs].id,
          signAddres: this.freeEquipments[index].equipments[indexs].signAddres,
          signLng: this.freeEquipments[index].equipments[indexs].signLat,
          signLat: this.freeEquipments[index].equipments[indexs].signLng,
        };
        _freeOrderImplement(from).then((res) => {
          if (res.code == "1") {
            this.$message(res.msg);
            this.query();
            this.freeEquipments[index].equipments[indexs].signAddres = "";
          }
        });
      } else {
        this.$message("设备地址不能为空");
      }
    },
    // 获取详情数据
    getorderinfo(from) {
      _getorderInfo(from).then((res) => {
        this.tableData = Object.assign(res.data);
        this.flag = res.data.flag
        res.data.contentList.forEach((item) => {
          var datas = [];
          item.serviceList.forEach((oneitem) => {
            datas.push(oneitem);
          });
          item.serviceList = [{ datas: datas }];
        });
        this.contentList = res.data.contentList;
        this.freeEquipments = res.data.freeEquipments;
        this.newId = res.data.id; //往后端传
        this.dataArr = res.data.equipmentApplys; //时间线数据
      });
    },
    // 指派
    assigned() {
      this.$refs.assigned.init(this.tableData.id);
    },
    // 设备申请
    apply() {
      this.$refs.changeApplication.open(
        "设备申请",
        this.tableData.orderId,
        this.tableData.id
      );
    },
    // 验收的接口
    implementation() {
      this.$refs.asas.init(this.tableData.id,this.tableData.spDid);
    },
    // 完成的接口
    complete() {
      this.btnDisab = true
      let from = {
        orderId: this.newId,
      };
      _getorderOver(from).then((res) => {
        if (res.code == "1") {
           this.btnDisab = false
          this.$router.push({
            path: "/main/maintenance/order",
            query: { selected: "70" },
          });
          this.$message(res.msg);
        }else{
          this.btnDisab = false
        }
      }).catch(()=>{
        this.btnDisab = false
      });
    },
  },
};
